@import '../../../styles/variables';
.filter-collapsible-header {
    top: 10px;
    left: 0;
    right: 0;
    padding: 0;
    pointer-events: none;
    
    .container {
        pointer-events: none;
    }

    .filter-collapsible-header-padding {
        position: relative;            
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        padding: 0 32px;

        .text-wrapper {
            justify-content: center;
            height: 100%;
            // max width is adjusted in media queries below
            max-width: 20%;

            .text-cont {
                font-size: 20px;
                line-height: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $grey--strong;

                .title {
                    color: $text;
                }
            }

            .timestamp-subtitle {
                font-size: 12px;
                line-height: 16px;
                color: $grey;
                font-weight: 500;
            }
        }

        .spacer {
            flex: 1;
            height: 100%;
        }

        .filters-cont {
            max-width: 230px;
            margin-right: 14px;
            margin-left: 14px;
        }

        .overview-cont {
            justify-content: space-between;
            height: 32px;
            width: 90px;
            margin-left: 14px;

            .fullWidth {
                width: 32px;
                height: 32px;
                background-color: $grey--strong;
                // TODO: replace with material icon
                mask-image: url('../../../assets/icons/fullscreen-button.svg');
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: 30px 20px;
                overflow: hidden;
                cursor: pointer;
                transition: all .3s ease-in-out;

                &.active {
                    background-color: $text;
                }
            }
        }

        .map-cont {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 26px;
            width: 110px;

            .fullscreen-button {
                width: 32px;
                height: 32px;
                color: $grey--strong;
                cursor: pointer;
                transition: all .3s ease-in-out;

                .material-icon {
                    font-size: 32px;
                    user-select: none;
                }
    
                &.active {
                    color: $text;
                }
            }

            .graphs {
                width: 32px;
                height: 32px;
                color: $grey--strong;
                cursor: pointer;
                transition: all .3s ease-in-out;
                
                .material-icon {
                    font-size: 32px;
                    user-select: none;
                }
    
                &.active {
                    color: $text;
                }
            }
        }
    }

    .active-counter {
        font-size: 1.4rem;
        line-height: 1;
    
        .active-filters {
            display: block;
            overflow: hidden;
            text-overflow: clip;
        }

        .stations-counter {
            font-size: 1.2rem;
            line-height: 1.6;
            opacity: .7;
        }
    }

    &.map-header {
        top: 10px;

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.body-wrapper {
    display: grid;
    grid-template-columns: 1fr 270px;
    min-height: 64px;

    &.minimize-slot-right {
        grid-template-columns: 1fr 100px;
    }

    .slot-right {
        padding-top: 19px;

        .save-btn {
            height: 32px;
            display: block;
            border: 1px solid $grey;
            outline-color: transparent;
            background-color: white;
            padding: 4px 12px;
            cursor: pointer;
            border-radius: 2px;
            transition: all calc($animation-duration / 2) ease-in-out;

            &.loading {
                padding: 7px 12px 4px 10px;
            }

            &:hover {
                background: $background--light;
            }

            &[disabled] {
                background-color: $background--light;
            }
        }

        .icon-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline-color: transparent;
            background: transparent;
            height: 32px;
            width: 32px;
            padding-top: 4px;
            cursor: pointer;

            span {
                font-size: 27px;
                color: $grey--strong;
                transition: all calc($animation-duration / 2) ease-in-out;
            }

            &.reset {
                span {
                    margin-top: 2px;
                }
            }

            &:hover {
                span {
                    color: $text;
                }
            }

            &[disabled] {
                span {
                    color: $grey;
                }
            }
        }
    }
}

// kpi styles
.kpi-collapsible {

    .container {
        padding: 0px;
        width: 230px;

        .collapsible {
            margin-top: 0px;
            box-shadow: none;

            .collapsible-header {
                height: 32px !important;
                box-shadow: none !important;
                min-height: unset !important;

                .chevron {
                    top: -1px !important;
                    right: -1px !important;
                    width: 32px !important;
                    height: 32px !important;
                }
            }

            .collapsible-body {
                width: 90vw;
                max-width: 1754px;
            }
        }
    }
}

.kpi-header {
    top: 0;

    .container {
        padding: 0px;
        width: 230px;

        .filter-collapsible-header-padding {
            height: 32px;
            padding: 0;

            .filters-cont {
                right: 0px;
                top: -10px;
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: $bp--lg) {
    .filter-collapsible-header {
        .filter-collapsible-header-padding {
            .text-wrapper {
                max-width: 30%;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .filter-collapsible-header {
        .filter-collapsible-header-padding {
            .text-wrapper {
                max-width: 50%;
            }
        }
    }
}
