@import '../../../styles/variables';
app-date-range {
    .date-range-wrapper {

        nz-range-picker, nz-date-picker {
            background-color: $white;
            border: 1px solid $grey;
            height: 40px;
            flex-grow: 1;

            &:hover {
                border-right-width: 2px;
                border-color: $primary;
            }

            &.small {
                height: 32px;
            }

            // fix initial placement
            .ant-picker-active-bar {
                position: absolute;
                background: $primary;
            }

            .ant-picker-input {
                input {
                    color: $text;
                    width: 80px;
                }

                &:nth-of-type(3) {
                    input {
                        color: $primary;
                    }
                }
            }

            .ant-picker-separator, .ant-picker-suffix {
                color: $text;
            }

            .ant-picker-clear {
                color: $text;
                background-color: $white;
            }
        }

        &.show-time {
            nz-range-picker, nz-date-picker {
                .ant-picker-input {
                    input {
                        width: 120px;
                    }
                }
            }
        }

        .ant-picker-focused {
            box-shadow: 0 0 0 2px $primary--dark;
        }

        .inputs-wrapper {
            .daterange-preselects {
                margin-left: 4px;

                button {
                    position: relative;
                    background: $white;
                    outline-color: transparent;
                    border: 1px solid $grey;
                    color: $text;
                    font-size: 15px;
                    height: 40px;
                    padding: 2px 10px;
                    min-width: 40px;
                    cursor: pointer;
                    transition: all calc($animation-duration / 2) ease-in-out;

                    &:not(:last-of-type) {
                        border-right-color: transparent;
                    }

                    &:first-of-type {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }
                    &:last-of-type {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }

                    &:hover {
                        background: $background--light;
                    }

                    &.active {
                        border-color: $primary;
                        color: $primary;
                        border-right-color: $primary;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-image: none;
                            background-color: $status--failure;
                            mask-image: url('./../../../assets/icons/close.svg');
                            mask-repeat: no-repeat;
                            mask-size: 14px 14px;
                            mask-position: center;
                            opacity: 0;
                            transition: opacity calc($animation-duration / 2) ease-in-out;
                        }

                        &:hover {
                            color: $white;
                            background: $white;

                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .reset-date-range {
                height: 30px;
                width: 30px;
                margin: 5px;
                padding: 4px;
                border: none;
                outline-color: transparent;
                background: transparent;
                cursor: pointer;
                
                .reset-img {
                    height: 22px;
                    width: 22px;
                    transition: filter calc($animation-duration / 2) ease-in-out;
                    transform: rotate(45deg);
                    filter: grayscale(1);
                    background-image: none;
                    background-color: $primary;
                    mask-image: url('../../../assets/icons/reset.svg');
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                }

                &:hover {
                    .reset-img {
                        filter: grayscale(0);
                    }
                }
            }

            &.small {
                .daterange-preselects {
                    button {
                        height: 32px;
                        padding: 2px 10px;
                    }
                }

                .reset-date-range {
                    .reset-img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        &.vertical {
            .inputs-wrapper {
                width: 100%;

                .daterange-preselects {
                    margin-left: 0px;
                    margin-top: 5px;
                    display: grid;
                    flex-grow: 1;
                    grid-template-columns: repeat(auto-fit, minmax(40px, 65px));                
                }

                .reset-date-range {
                    margin: 10px 10px 5px 5px;
                }
            }
        }

        &.evc-style {
            nz-range-picker, nz-date-picker {
                border: 1px solid $grey;
                border-radius: 3px;
            }

            .ant-picker-focused {
                box-shadow: 0 0 0 1px $off-white;
            }

            .icon {
                display: inline-block;
                height: 40px;
                width: 40px;
                background-color: $background;
                border: 1px solid $grey;
                border-left: none;
                padding: 6px 8px 8px 8px;
                border-radius: 0 3px 3px 0;
                color: $grey--strong;
                margin-right: -12px;
            }

            .reset-date-range {
                height: 40px;
                width: 40px;
                margin: 0;
                border-radius: 0 3px 3px 0;
                border: 1px solid $grey;
                border-left: 1px dashed $grey--strong;
                position: relative;
                background: $background;
                transition: all calc($animation-duration / 2) ease-in-out;

                &::after {
                    content: "rotate_right";
                    font-size: 22px;
                    font-family: 'Material Icons';
                    color: $grey--strong;    
                }

                .reset-img {
                    display: none;
                }
            }

            &.has-reset {
                .icon {
                    border-right: none;
                    border-radius: 0;
                }

                // do not round corners where button is appended
                nz-range-picker, nz-date-picker {
                    border-radius: 3px 0 0 3px;
                }

                // add focus styling to neighboring reset button
                .ant-picker-focused {
                    ~ div {
                        button {
                            box-shadow: 0 0 0 1px $off-white;
                        }
                    }
                }
            }

            &.small {
                .icon {
                    height: 32px;
                    width: 32px;
                    padding: 7px;
                    font-size: 16px;
                    margin-right: -8px;
                }
                .reset-date-range {
                    height: 32px;
                    width: 32px;

                    &::after {
                        line-height: 23px;
                        font-size: 15px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

// disabled end date
app-date-range .date {
    pointer-events: none;
    .ant-picker-input:nth-of-type(1) {
        input {
            pointer-events: all;
        }
    } 
    .ant-picker-input:nth-of-type(3) {
        input {
            color: $text--super-light !important;
            cursor: not-allowed;
        }
    }
    .ant-picker-separator {
        cursor: not-allowed;
    }
    .ant-picker-active-bar {
        display: none;
    }
    .ant-picker-clear {
        pointer-events: all;
    }
}
